import React, { useState, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import "./style.scss";
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import { DropDownMenu } from '../../../src/components/Dropdownmenu';
import { DropDownSubMenu } from '../../../src/components/dropdownsubmenu';
import { AxiosHeaders } from "axios";
const HeaderBottom = (props) => {
  const [search, setSearch] = useState();
  const [responsive, setResponsive] = useState(false);
  const [trigger, setTrigger] = useState(false);
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   console.log(search);
  // };
  // const clickHandler = () => {
  //   setTrigger(!trigger);
  // };
  // const responsiveHandler = () => {
  //   setResponsive(!responsive);
  // };
  return (
 
                <Navbar   bg="dark"  expand="s" variant="dark">
              
      <Container>
       
        <Navbar.Brand href="/#/"><img  style={{ borderRadius: '0' }}  src={logo} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="/#/">Home</Nav.Link> */}
          
              <DropDownMenu title ="About" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/Introduction">Introduction</NavDropdown.Item><NavDropdown.Item href="/#/VisionMission">Vision & Mission</NavDropdown.Item><NavDropdown.Item href="/#/Chairmandesk">Chairman'S Desk</NavDropdown.Item><NavDropdown.Item href="/#/DirectorDesk">Director'S Desk</NavDropdown.Item><NavDropdown.Item href="/#/PrincipalDesk">Principal'S Desk</NavDropdown.Item><DropDownSubMenu title ="Campus" href="#"><NavDropdown.Item href="/#/Building">Building</NavDropdown.Item><NavDropdown.Item href="/#/Classroom">Classroom</NavDropdown.Item><NavDropdown.Item href="/#/Labs">Labs</NavDropdown.Item><NavDropdown.Item href="/#/Library">Library</NavDropdown.Item><NavDropdown.Item href="/#/KnowledgeCentre">Knowledge Centre</NavDropdown.Item><NavDropdown.Item href="/#/MusicRoom">Music & Dance Room</NavDropdown.Item> <NavDropdown.Item href="/#/ArtRoom">Art Room</NavDropdown.Item><NavDropdown.Item href="/#/InfirmaryRoom">Infirmary Room</NavDropdown.Item><NavDropdown.Item href="/#/PlayStation">Play Station</NavDropdown.Item><NavDropdown.Item href="/#/ActivityRoom">Activity  Room</NavDropdown.Item><NavDropdown.Item href="/#/MagicalWorldofChildrenCinema">Magical World Of Children Cinema</NavDropdown.Item><NavDropdown.Item href="/#/Auditorium">Auditorium</NavDropdown.Item><NavDropdown.Item href="/#/SportsCourts">Sports Courts</NavDropdown.Item></DropDownSubMenu><NavDropdown.Item href="/#/Transport">Transport</NavDropdown.Item> <NavDropdown.Item href="/#/POCSOCommittee">Committee And Policies</NavDropdown.Item> <NavDropdown.Item href="/#/ClassWiseStrength">Class Wise Strength</NavDropdown.Item><NavDropdown.Item href="/#/AnnualReport">Annual Report</NavDropdown.Item>
              <NavDropdown.Item href="/#/contact">Reach Us</NavDropdown.Item></DropDownMenu><DropDownMenu title ="Academics" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/TheAcademicBlock">Cbse Affiliated</NavDropdown.Item><DropDownSubMenu title ="Curriculum" href="#"><NavDropdown.Item href="/#/DetailsofCurriculum">Details Of Curriculum</NavDropdown.Item><NavDropdown.Item href="/#/SubjectsStreams">Subjects & Streams</NavDropdown.Item></DropDownSubMenu><NavDropdown.Item href="/#/Calendar">Calendar</NavDropdown.Item><NavDropdown.Item href="/#/ListofBooks">List Of Books</NavDropdown.Item></DropDownMenu><DropDownMenu title ="Beyond Books" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/GuidanceAndCounselling">Guidance And Counselling</NavDropdown.Item><NavDropdown.Item href="/#/PhysicalWellBeing">Physical Well Being</NavDropdown.Item><NavDropdown.Item href="/#/Cleanliness">Cleanliness</NavDropdown.Item><NavDropdown.Item href="/#/PersonalandSocialEducation">Personal And Social Education</NavDropdown.Item><NavDropdown.Item href="/#/HealthyDietSayNOtoJunkFood">Healthy Diet Say ‘No’ To Junk Food</NavDropdown.Item></DropDownMenu><DropDownMenu title ="Faculty" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/AboutthePrincipal">About The Principal</NavDropdown.Item><NavDropdown.Item href="/#/Heads">Heads</NavDropdown.Item><NavDropdown.Item href="/#/Teachers">Teachers</NavDropdown.Item>
              {/* <NavDropdown.Item href="/#/TeachersEnrichmentProgramme">Teachers Enrichment Programme</NavDropdown.Item> */}
              </DropDownMenu><DropDownMenu title ="Parents & Students" id="collasible-nav-dropdown"><DropDownSubMenu title ="Parents" href="#">
                {/* <NavDropdown.Item href="/#/EnquiryRegistration">Enquiry & Registration</NavDropdown.Item> */}
                <NavDropdown.Item href="/#/Admissions">Admissions</NavDropdown.Item>
              {/* <DropDownSubMenu title ="Fee Structure & Norms" href="#"> */}
                <NavDropdown.Item href="/#/FeeStructure">Fee Structure & Norms</NavDropdown.Item>
                {/* </DropDownSubMenu> */}
                <NavDropdown.Item href="/#/SchoolUniform">School Uniform</NavDropdown.Item><NavDropdown.Item href="/#/WithdrawalPolicy">Withdrawal Policy</NavDropdown.Item></DropDownSubMenu><DropDownSubMenu title ="Students" href="#">
                  {/* <DropDownSubMenu title ="School Codes & Policies" href="#"> */}
                    <NavDropdown.Item href="/#/SchoolCodesPolicies">School Codes & Policies</NavDropdown.Item>
                    {/* <DropDownSubMenu title ="School Houses & Clubs" href="#"> */}
                      <NavDropdown.Item href="/#/SchoolHouseSystem">School House System</NavDropdown.Item>
                      {/* </DropDownSubMenu> */}
                      <NavDropdown.Item href="/#/ProctorialBoard">Proctorial Board</NavDropdown.Item>
                      {/* </DropDownSubMenu> */}
                      <NavDropdown.Item href="/#/DisciplinePolicy">Discipline Policy</NavDropdown.Item><NavDropdown.Item href="/#/AntibullingPolicy">Anti–Bulling Policy</NavDropdown.Item></DropDownSubMenu>
                      </DropDownMenu>
                        <Nav.Link href="/#/Achievements">Achievements</Nav.Link>
                       
              <DropDownMenu title ="Activities" id="collasible-nav-dropdown">
              <NavDropdown.Item  href="/#/MorningAssembly">Morning Assembly</NavDropdown.Item>
              {/* <DropDownSubMenu title ="Assemblies" href="#">
                <NavDropdown.Item href="/#/MorningAssembly">Morning Assembly</NavDropdown.Item>
                </DropDownSubMenu> */}
                <DropDownSubMenu title ="Competitions & Activities" href="#"><NavDropdown.Item href="/#/Sessions">Sessions</NavDropdown.Item><NavDropdown.Item href="/#/EducationaltoursExcursions">Educational Tours & Excursions</NavDropdown.Item><NavDropdown.Item href="/#/AnnualPrizeDistribution">Annual Prize Distribution</NavDropdown.Item></DropDownSubMenu></DropDownMenu><DropDownMenu title ="Gallery" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/Album">Photo Gallery</NavDropdown.Item><NavDropdown.Item href="/#//ALBUM.ASPX?Galr=Media">Media Corner</NavDropdown.Item><NavDropdown.Item href="/#/VideoGallery">Video Gallery</NavDropdown.Item></DropDownMenu>
              <Nav.Link href="/#/Download">Download</Nav.Link>
              {/* <DropDownMenu title ="Download" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/#/Download">All</NavDropdown.Item>
                <NavDropdown.Item href="/#/Syllabus">Syllabus</NavDropdown.Item>
                <NavDropdown.Item href="/#/TimeTable">Time-Table</NavDropdown.Item>
                <NavDropdown.Item href="/#/Calendar">Calendar</NavDropdown.Item>
                <NavDropdown.Item href="/#/DateSheet">Date Sheet</NavDropdown.Item>
                <NavDropdown.Item href="/#/HolidaysHomeWork">Holidays’ Home Work</NavDropdown.Item>
                <DropDownSubMenu title ="Application Formats" href="#"> 
                  <NavDropdown.Item href="/#/StudentProfileUpdationPerfroma">Student Profile Updation Perfroma</NavDropdown.Item>
                  <NavDropdown.Item href="/#/SchoolLeavingCertificate">School Leaving Certificate</NavDropdown.Item>
                  <NavDropdown.Item href="/#/ChangeofAddressPhoneNo">Change Of Address/Phone No.</NavDropdown.Item>
                  <NavDropdown.Item href="/#/BonafideFeeCertificate">Bonafide/Fee Certificate</NavDropdown.Item>
                  <NavDropdown.Item href="/#/ChangeinTransportFacility">Change In Transport Facility</NavDropdown.Item>
                  <NavDropdown.Item href="/#/IssueofDuplicateICard">Issue Of Duplicate I-Card</NavDropdown.Item>
                  </DropDownSubMenu></DropDownMenu>
                  */}
                  <DropDownMenu title ="Career" id="collasible-nav-dropdown"><NavDropdown.Item href="/#/Career">Career</NavDropdown.Item>
                  <NavDropdown.Item href="/#/Application">Apply Online</NavDropdown.Item></DropDownMenu>
              {/* <DropDownMenu title ="Online Forms" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/#/EnquiryForm">Enquiry Form</NavDropdown.Item>
                <NavDropdown.Item href="/#/RegistrationForm">Registration Form</NavDropdown.Item>
                <NavDropdown.Item href="/#/AdmissionForm">Admission Form</NavDropdown.Item>
                <NavDropdown.Item href="/#/PaymentOption">Payment Option</NavDropdown.Item>
                <NavDropdown.Item href="/#/StudentsFeedBackForm">Students Feed Back Form</NavDropdown.Item>
                <NavDropdown.Item href="/#/CareerForm">Career Form</NavDropdown.Item>
                <NavDropdown.Item href="/#/EmployeeLeaveApplicationForm">Employee Leave Application Form</NavDropdown.Item>
                </DropDownMenu> */}
                <Nav.Link href="/#/Login?LoginType=Administrator">Login</Nav.Link><Nav.Link href="/#/TcVerify">Verify T.C.</Nav.Link><Nav.Link href="/#/PublicDisclosure">Public Disclosure</Nav.Link>
            
          </Nav>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};
export default HeaderBottom;
